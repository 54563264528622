import React, { useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie';

import Axios from 'axios';
import storage from '../../helpers/storage'
import loader from '../../helpers/loader'

import VaceContext from './vaceContext';
import VaceReducer from './vaceReducer';

import {
    SET_LOADING,
    UNSET_LOADING,
    GET_BUSINESS,
    GET_WALLET,
    GET_ACCOUNTS,
    GET_ACCOUNT,
    GET_BILLERS,
    SET_WALLET,
    GET_TRANSACTIONS,
    SET_PAGINATION,
    SET_TOTAL,
    SET_COUNT,
    GET_BENEFICIARIES,
    GET_BUSINESS_BANKS,
    SET_SEARCH,
    GET_BILL_CATEGORIES,
    GET_MOBILE_PLANS,
    GET_PRODUCTS,
    GET_PRODUCT,
    SET_SPLITS,
    GET_PAYMENT_LINKS,
    GET_PAYMENT_LINK,
    GET_VACE_OVERVIEW,
    SET_RESPONSE,
    SET_BANK,
    GET_TRANSACTION,
    GET_REFUNDS,
    GET_CHARGEBACK,
    GET_VACE_GRAPH,
    SET_APIDONE,
    SET_INVOICE_ITEMS,
    GET_INVOICES,
    GET_INVOICE,
    GET_SUBACCOUNT,
    GET_SUBACCOUNTS,
    GET_WEBHOOK,
    GET_BUSINESS_SETTINGS,
    GET_BILL_PRODUCTS,
    SET_PAYMENT_METHODS
} from '../types'
import { ICheckoutOption, IInvoiceItem, IListQuery, IProductSplit, IResponse, ISearchProps, IVaceOverview, IYearChartData } from '../../utils/types';
import { ValidateBillerDTO } from '../../dtos/vas.dto';
import { SetGraphDataDTO, SetPieDataDTO, SetYearChartDTO } from '../../dtos/chart.dto';
import body from '../../helpers/body';
import { FeatureType } from '../../utils/enums';

const VaceState = (props: any) => {

    const cookie = new Cookies();

    const exp = new Date(
        Date.now() + 70 * 24 * 60 * 60 * 1000
    )

    const navigate = useNavigate()
    Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

    const initialState = {
        overview: {},
        graph: {},
        business: {},
        settings: {},
        wallet: {},
        webhook: {},
        accounts: [],
        billers: [],
        biller: {},
        dataPlans: [],
        splits: [],
        bills: [],
        billProducts: [],
        account: {},
        beneficiaries: [],
        beneficiary: {},
        chargebacks: [],
        chargeback: {},
        refunds: [],
        refund: {},
        banks: [],
        bank: {},
        invoices: [],
        invoice: {},
        invoiceItems: [],
        products: [],
        product: {},
        paymentLinks: [],
        paymentLink: {},
        subaccounts: [],
        subaccount: {},
        transactions: [],
        transaction: {},
        paymentMethods: [],
        total: 0,
        count: 0,
        pagination: {},
        progress: 0,
        loading: false,
        apiDone: false,
        response: {},
        search: {
            error: false,
            message: '',
            data: [],
            list: []
        },
    }

    const [state, dispatch] = useReducer(VaceReducer, initialState);

    const logout = async () => {

        storage.clearAuth();
        localStorage.clear();
        cookie.remove('token');
        cookie.remove('userType');

        navigate('/login');
        // wait for logout API
        await Axios.post(`${process.env.REACT_APP_AUTH_URL}/auth/logout`, {}, storage.getConfig());
    }

    /**
     * @name getTransaction
     * @param id 
     */
    const getTransaction = async (id: string) => {

        setLoading()
        await Axios.get(`${process.env.REACT_APP_API_URL}/transactions/${id}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_TRANSACTION,
                    payload: resp.data.data
                });

                if (resp.data.data.refunds && resp.data.data.refunds.length > 0) {
                    dispatch({
                        type: GET_REFUNDS,
                        payload: resp.data.data.refunds
                    })
                }

                if (resp.data.data.chargeback && resp.data.data.chargeback) {
                    dispatch({
                        type: GET_CHARGEBACK,
                        payload: resp.data.data.chargeback
                    })
                }

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get transaction ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get transaction ${err}`)

                }

            })

    }

    /**
     * @name verifyTransaction
     * @param ref 
     */
    const verifyTransaction = async (ref: string) => {

        setLoading();

        await Axios.post(`${process.env.REACT_APP_API_URL}/transactions/verify`, { reference: ref }, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_TRANSACTION,
                    payload: resp.data.data
                });

                if (resp.data.data.business) {
                    dispatch({
                        type: GET_BUSINESS,
                        payload: resp.data.data.business
                    })
                }

                if (resp.data.data.payment) {
                    dispatch({
                        type: GET_PAYMENT_LINK,
                        payload: resp.data.data.payment
                    })
                }

            })
            .catch((err: any) => {

                let { data, message, status, errors, error } = err.response.data

                if (status === 404) {
                    message = 'Transaction does not exist'
                }

                setResponse({
                    data,
                    message,
                    status,
                    errors,
                    error
                });

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not verify transaction ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not verify transaction ${err}`)

                }

                unsetLoading()

            })

    }

    /**
     * @name getProduct
     * @param id 
     */
    const getProduct = async (id: string) => {

        setLoading()

        await Axios.get(`${process.env.REACT_APP_API_URL}/products/${id}`, storage.getConfigWithBearer())
            .then((resp) => {

                dispatch({
                    type: GET_PRODUCT,
                    payload: resp.data.data
                });

                if (resp.data.data.splits.length > 0) {

                    const allSplits = resp.data.data.splits.map((x: any) => {

                        let split: IProductSplit = {
                            type: x.type,
                            value: x.value,
                            label: x.label,
                            flat: x.type === 'flat' ? true : false,
                            percentage: x.type === 'percentage' ? true : false,
                            dest: x.destination,
                            bank: {
                                accountName: x.bank.accountName,
                                accountNo: x.bank.accountNo,
                                bankCode: x.bank.bankCode,
                                bankName: x.bank.bankName
                            }
                        }

                        return split;

                    })

                    setSplits(allSplits);

                }

            })
            .catch((err: any) => {

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get product ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get product ${err}`)

                }

            })

    }

    const getPaymentLinkByLabel = async (label: string) => {

        setLoading()

        await Axios.get(`${process.env.REACT_APP_API_URL}/paymentlinks/url/${label}`, storage.getConfigWithBearer())
            .then((resp) => {

                const { invoice, product } = resp.data.data;

                if ((resp.data.data.feature === FeatureType.INVOICE && !invoice.isEnabled) || (resp.data.data.feature === FeatureType.PRODUCT && !product.isEnabled)) {

                    setResponse({
                        data: null,
                        message: `This payment link is not properly confgured. ${body.captialize(resp.data.data.feature)} is not enabled.`,
                        status: 400,
                        errors: [],
                        error: true
                    })

                } else if (resp.data.data.feature === FeatureType.INVOICE && invoice.status === 'paid') {

                    setResponse({
                        data: null,
                        message: `Payment link is attached to invoice #${invoice.number} and invoice is already paid`,
                        status: 400,
                        errors: [],
                        error: true
                    })

                } else {

                    dispatch({
                        type: GET_PAYMENT_LINK,
                        payload: resp.data.data
                    });

                    if(resp.data.data.business){
                        dispatch({
                            type: GET_BUSINESS,
                            payload: resp.data.data.business
                        });
                    }

                    if(resp.data.data.invoice){
                        dispatch({
                            type: GET_INVOICE,
                            payload: resp.data.data.invoice
                        })
                    }

                    if(resp.data.data.product){
                        dispatch({
                            type: GET_PRODUCT,
                            payload: resp.data.data.product
                        })
                    }

                }

            })
            .catch((err: any) => {

                let { data, message, status, errors, error } = err.response.data

                if (status === 422) {
                    message = 'This payment details is currently deactivated. Contact business to enable you to pay.'
                } else if (status === 403) {
                    message = 'This payment configuration is incorrect. Contact business to enable you to pay'
                } else if (status === 404) {
                    message = 'This payment details does not exist on Terraswitch.'
                }

                setResponse({
                    data,
                    message,
                    status,
                    errors,
                    error
                })

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get payment link ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get payment link ${err}`)

                }

                unsetLoading()

            })

    }

    const getInvoiceByCode = async (code: string, preview: boolean) => {

        setLoading()

        await Axios.get(`${process.env.REACT_APP_API_URL}/invoices/by-code/${code}`, storage.getConfigWithBearer())
            .then((resp) => {

                if (!resp.data.data.payment) {

                    if (preview === false) {

                        setResponse({
                            data: null,
                            message: 'This invoice is not properly confgured. No payment link attached.',
                            status: 400,
                            errors: [],
                            error: true
                        })

                    } else {

                        dispatch({
                            type: GET_INVOICE,
                            payload: resp.data.data
                        });

                        setInvoiceItems(resp.data.data.items);

                    }


                } else {

                    dispatch({
                        type: GET_INVOICE,
                        payload: resp.data.data
                    });

                    setInvoiceItems(resp.data.data.items);
                }





            })
            .catch((err: any) => {

                let { data, message, status, errors, error } = err.response.data

                if (status === 422) {
                    message = 'This invoice is currently deactivated. Contact business to enable you to view details.'
                } if (status === 404) {
                    message = 'This invoice does not exist on Terraswitch.'
                }

                setResponse({
                    data,
                    message,
                    status,
                    errors,
                    error
                })

                if (err && err.response && err.response.data && err.response.data.status === 401) {

                    logout();

                } else if (err && err.response && err.response.data) {

                    console.log(`Error! Could not get invoice ${err.response.data}`)

                } else if (err && err.toString() === 'Error: Network Error') {

                    loader.popNetwork();

                } else if (err) {

                    console.log(`Error! Could not get invoice ${err}`)

                }

                unsetLoading()

            })

    }

    const setSearch = ({ error, message, data, list }: Partial<ISearchProps>) => {
        dispatch({
            type: SET_SEARCH,
            payload: { error, message, data, list }
        })
    }

    const setLoading = () => {
        dispatch({
            type: SET_LOADING
        })
    }

    const unsetLoading = () => {
        dispatch({
            type: UNSET_LOADING,
        })
    }

    const setAPIDone = (flag: boolean) => {
        dispatch({
            type: SET_APIDONE,
            payload: flag
        })
    }

    const setPaymentLink = (data: any) => {
        dispatch({
            type: GET_PAYMENT_LINK,
            payload: data
        })
    }

    const setBank = (data: any) => {
        dispatch({
            type: SET_BANK,
            payload: data
        })
    }

    const setInvoice = (data: any) => {
        dispatch({
            type: GET_INVOICE,
            payload: data
        })
    }

    const setSplits = (data: Array<IProductSplit>) => {
        dispatch({
            type: SET_SPLITS,
            payload: data
        })
    }

    const setInvoiceItems = (data: Array<IInvoiceItem>) => {
        dispatch({
            type: SET_INVOICE_ITEMS,
            payload: data
        })
    }

    const setResponse = (data: Partial<IResponse>) => {
        dispatch({
            type: SET_RESPONSE,
            payload: data
        })
    }

    const setPaymentMethods = (data: Array<ICheckoutOption>) => {

        dispatch({
            type: SET_PAYMENT_METHODS,
            payload: data
        })

    }

    return <VaceContext.Provider
        value={{
            business: state.business,
            chargeback: state.chargeback,
            refund: state.refund,
            invoice: state.invoice,
            invoiceItems: state.invoiceItems,
            paymentLink: state.paymentLink,
            subaccount: state.subaccount,
            bank: state.bank,
            splits: state.splits,
            product: state.product,
            transaction: state.transaction,
            paymentMethods: state.paymentMethods,
            search: state.search,
            total: state.total,
            count: state.count,
            pagination: state.pagination,
            progress: state.progress,
            loading: state.loading,
            apiDone: state.apiDone,
            response: state.response,
            getProduct,
            getPaymentLinkByLabel,
            getInvoiceByCode,
            getTransaction,
            verifyTransaction,
            setSearch,
            setSplits,
            setPaymentLink,
            setLoading,
            setResponse,
            setAPIDone,
            setInvoiceItems,
            setInvoice,
            setBank,
            setPaymentMethods,
            unsetLoading,
        }}
    >
        {props.children}

    </VaceContext.Provider>

}

export default VaceState